.grid-container {
  width: 100%;
  padding: 2rem;
  
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  
  
}

.grid-card {
  position: relative;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.grid-card:hover {
  transform: translateY(-5px);
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.grid-card:hover .card-image img {
  transform: scale(1.05);
}


.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  transform: translateY(40%);
  transition: transform 0.3s ease;
}

.grid-card:hover .card-content {
  transform: translateY(0);
  opacity: 1;
}

.grid-card:hover .card-content p{
  opacity: 1;
}

.card-content h3 {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 1;
  
}

.card-content p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  opacity: 0;
}

.card-content:hover p {
  opacity: 1;
}



.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tech-tag {
  background: rgba(0, 255, 255, 0.1);
  color: #00ffff;
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.8rem;
}

.project-link {
  display: inline-block;
  color: #00ffff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.project-link:hover {
  color: #00ffff;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.grid-card:hover .card-overlay {
  opacity: 1;
}

.view-project {
  color: #fff;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border: 2px solid #00ffff;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.view-project:hover {
  background: #00ffff;
  color: #000;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .grid-card {
    height: 350px;
  }

  .card-content {
    transform: translateY(0);
    /* background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0.8) 80%,
      rgba(0, 0, 0, 0.6) 100%
    ); */
    padding: 1.5rem;
    height: 41%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 1;
  }

  .card-content h3 {
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
    opacity: 1;
  }

  .card-content p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    opacity: 1;
    line-height: 1.4;
  }

  .technologies {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    margin-bottom: 0;
    opacity: 1;
  }

  .tech-tag {
    font-size: 0.75rem;
    padding: 0.2rem 0.6rem;
  }
}

/* Ajustements pour très petits écrans */
@media screen and (max-width: 375px) {
  .grid-card {
    height: 300px;
  }

  .card-content {
    padding: 1.2rem;
    height: 50%
  }

  .card-content h3 {
    font-size: 1.2rem;
  }

  .card-content p {
    font-size: 0.85rem;
  }

  .tech-tag {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }
}
