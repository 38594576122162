.profile-header {
  display: flex;
  align-items: center;
  gap: 6rem;
  padding: 8rem 0 4rem 0;
  max-width: 1200px;
  margin: 0 auto;
}

.profile-image {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border: 4px solid rgba(255, 255, 255, 0.1);
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info h1 {
  font-size: 4rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
  letter-spacing: 2px;
}

.profile-info h2 {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}

/* Responsive */
@media (max-width: 1024px) {
  .profile-header {
    padding: 7rem 2rem 4rem 2rem;
    gap: 4rem;
  }

  .profile-image {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    text-align: center;
    padding: 6rem 1rem 3rem 1rem;
    gap: 3rem;
  }

  .profile-image {
    width: 300px;
    height: 300px;
  }

  .profile-info h1 {
    font-size: 3rem;
  }

  .profile-info h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .profile-header {
    padding: 5rem 1rem 2rem 1rem;
  }

  .profile-image {
    width: 250px;
    height: 250px;
  }

  .profile-info h1 {
    font-size: 2.5rem;
  }

  .profile-info h2 {
    font-size: 1.5rem;
  }
} 