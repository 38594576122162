.navbar {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0); /* Fond semi-transparent */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  /*position: fixed;*/
  width: 100vw;
  top: 0;
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.navbar-logo {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  height: 40px;
  margin-left: 2%;
  
}

.logo-img {
  height: 40px;
  width: auto;
  margin-right: 40px;
  vertical-align: middle;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-left: 2rem;
}

.nav-link {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  /*background-color: #00ffff;*/
  /*background: #01dada;*/
  background: rgba(0, 255, 255, 0.6);
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

.nav-link:hover {
  /*color: #00ffff; Couleur au survol */
  /*color: #01dada;*/
  color: rgba(0, 255, 255, 0.6);
}

/* Styles du hamburger */
.hamburger {
  display: none;
  cursor: pointer;
  margin-left: auto;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}

/* Pour rendre la navbar responsive */
@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }


  .navbar-logo {
    margin-left: 3%;
  }

  .navbar-brand {
    margin-bottom: 1rem;
  }

  .navbar-links {
    position: fixed;
    top: 60px; /* Hauteur de la navbar */
    right: -100%;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.95); /* Fond plus opaque */
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100vh; /* Pleine hauteur */
    text-align: center;
    transition: 0.3s;
    padding: 2rem 0;
    z-index: 999; /* Juste en dessous de la navbar */
  }

  .navbar-links li {
    margin: 0.5rem 0;
  }

  .navbar-brand {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }

  .hamburger {
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .navbar-links {
    position: fixed;
    left: -100%;
    top: 70px;
    gap: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: calc(100vh - 70px);
    transition: 0.3s;
    padding: 10px 0;
  }

  .navbar-links.active {
    left: 0;
  }

  .navbar-links li {
    margin: 5px 0;
    width: 100%;
    padding: 0 10px;
  }

  .nav-link {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 8px 1px;
    top: 100%;
    font-size: clamp(1.4rem, 8vw, 1.8rem);
  }

  .nav-link::before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 7px;
    background-color: #0c0c0c;
    border: 2px solid #e4e4e4;
  }

  .nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    background-color: #00ffff;
    transition: width 0.3s ease;
  }

  .nav-link:hover::after {
    width: 22%;
  }
}

/* iPhone SE, petits téléphones */
@media screen and (max-width: 375px) {
  .nav-links {
      top: 50px;
      height: calc(100% - 50px);
  }
}

/* iPhone 12 Pro */
@media screen and (max-width: 390px) {
  .nav-links {
      top: 55px;
      height: calc(100% - 55px);
  }
}

/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
  .nav-links {
      top: 58px;
      height: calc(100% - 58px);
  }
}

/* Samsung Galaxy S20, S21, S22 */
@media screen and (max-width: 412px) {
  .nav-links {
      top: 56px;
      height: calc(100% - 56px);
  }
}

/* Samsung Galaxy S8+, S9+, S10+ */
@media screen and (max-width: 360px) {
  .nav-links {
      top: 52px;
      height: calc(100% - 52px);
  }
}

/* Samsung Galaxy Fold (fermé) */
@media screen and (max-width: 280px) {
  .nav-links {
      top: 48px;
      height: calc(100% - 48px);
  }
}

/* iPad Mini */
@media screen and (min-width: 768px) and (max-width: 820px) {
  .nav-links {
      top: 65px;
      height: calc(100% - 65px);
  }
  
  .navbar {
      padding: 1.2rem 2rem;
  }
}

/* Samsung Galaxy Tab */
@media screen and (min-width: 820px) and (max-width: 1024px) {
  .nav-links {
      top: 68px;
      height: calc(100% - 68px);
  }
}



/* Pour éviter le rebond sur iOS */
html, body {
  overscroll-behavior-y: none;
}

.linkedin-icon {
  color: white;
  margin-left: auto;  /* Pour pousser l'icône à droite */
  margin-right: 20px;
  transition: color 0.3s ease;
}

.linkedin-icon:hover {
  color: rgba(1, 218, 218, 0.8);
}
