.projects-section {
    width: 95%;
    position: relative;
}

.projects-container {
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    position: relative;
}

.side-title {
    position: absolute;
    /*left: 10.5%;*/
    transform: translateY(-50%) rotate(90deg);
    font-size: 3.5rem;
    transform-origin: left center;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.8);
    color: transparent;
}

.projects-wrapper {
    width: 75%; /* 9/12 de l'écran */
    display: flex;
    flex-direction: column;
    gap: 12rem;
    position: relative;
}

.project-layout {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.project-info {
    width: 30%;
    padding: 1rem;
    color: white;
}

.project-info h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #fff;
    position: relative;
    padding-bottom: 1rem;
}

.project-info h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: rgba(0, 255, 255, 0.2);
    transition: width 0.3s ease;
}

.project-layout:hover h3::after {
    width: 100px;
}

.project-info p {
    margin-bottom: 1rem;
    color: #92aaa9;
    /*color: rgba(0, 255, 255, 0.2);*/

    line-height: 1.6;
}

.project-card {
    width: 70%;
    aspect-ratio: 16/9;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.project-image {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.project-card:hover .project-image img {
    transform: scale(1.05);
}

.project-tech {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.tech-tag {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    font-size: 0.8rem;
    color: #fff;
    transition: all 0.3s ease;
}

.project-layout:hover .tech-tag {
    background: rgba(0, 255, 255, 0.2);
    transform: translateY(-2px);
}

.project-link {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #92aaa9;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.project-link:hover {
    background: #92aaa9;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 255, 255, 0.2);
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .projects-wrapper {
        width: 100%;
    }
    
    .project-layout {
        flex-direction: column;
    }

    .side-title {
        position: static;
        transform: none;
        text-align: center;
        margin-bottom: 2rem;
        margin-left: 0;

        /*font-size: 20px;*/
    }

    .projects-container {
        width: 100%;
        padding: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .projects-wrapper {
        width: 100%;
    }

    .project-layout {
        flex-direction: column;
    }

    .project-info,
    .project-card {
        width: 90%;
        aspect-ratio: 16/9;
    }
}

/* Petits téléphones */
@media screen and (max-width: 430px) {
    
    .side-title {
        /*content: 'Latest\AProjects'; /* \A crée un saut de ligne */
        white-space: pre; /* Préserve le saut de ligne */
        line-height: 1.2;
        font-size: clamp(2.2rem, 6vw, 2.8rem);
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
    }

}

@media screen and (max-width: 375px) {
    .side-title {
        font-size: clamp(1.2rem, 6vw, 1.8rem);
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 0;
        margin-left: 0;
        text-align: center;
    }
}

@media (max-width: 1024px) {
  .project-layout {
    flex-direction: column;
    gap: 30px;
  }

  .project-info {
    width: 100%;
  }

  .project-card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .projects-wrapper {
    gap: 40px;
  }

  .project-info h3 {
    font-size: 1.8rem;
  }

  .project-info p {
    font-size: 1rem;
  }
}


