.banner {
    height : 100%;
    display: flex;
    /*justify-content: center;*/
    padding: 0 2rem;
    position: relative;
    padding-top: 50px;

}

.banner-content {
    text-align: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.banner h1 {
    color: #ffffff;
    -webkit-text-stroke: 2px rgba(255, 255, 255, 0.8);
    font-size: clamp(2.5rem, 8vw, 5rem);
    margin-bottom: clamp(1rem, 3vw, 2rem);
    letter-spacing: 2px;
    padding-left: 8%;
    padding-top: 10%;
    font-family: 'Quark', sans-serif;
    font-weight: 200;
    font-size: 80px;
}

.banner p {
    font-size: clamp(1rem, 3vw, 50px);
    padding-left: 8%;
    font-weight: 100;
    font-style: normal;
    /*line-height: 1.6;*/
    margin-bottom: clamp(1.5rem, 4vw, 3.125rem);
    max-width: 800px;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    text-align: start;
    font-family: 'Lato Thin', sans-serif;
    line-height: 1;
    color: #92aaa9;
    
}

.button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
}

.banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.banner button:hover svg {
    margin-left: 25px;
}

.txt-rotate > .wrap {
    border-right: 0.08em solid #b52f2f;
}




/* Media Queries */
@media screen and (max-width: 768px) {
    .banner {
        padding: 0 1rem;
        text-align: center;
        margin-top: 60px; /* Espace pour la navbar */
    }

    .banner h1 {
        font-size: clamp(2rem, 6vw, 2.5rem);
        text-align: center;
        padding-left: 0;
    }

    .banner-content {
        padding: 2rem 0;
    }
}

/* Tablettes */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .banner {
        margin-top: 65px;
    }

    

    .banner-content {
        padding: 3rem 0;
    }
}

/* Petits téléphones */
@media screen and (max-width: 375px) {
    .banner {
        margin-top: 50px;
        min-height: calc(100vh - 50px);
    }

    .banner h1 {
        font-size: clamp(2rem, 6vw, 2.5rem);
    }

    .banner p {
        font-size: clamp(0.9rem, 2.5vw, 1rem);
        padding: 0 1rem;
    }
}

/* Pour les écrans très larges */
@media screen and (min-width: 1440px) {
    .banner-content {
        max-width: 1400px;
    }

    .banner h1 {
        font-size: clamp(4rem, 5vw, 6rem);
    }
}

/* Pour les orientations paysage sur mobile */
@media screen and (max-width: 768px) and (orientation: landscape) {
    .banner {
        min-height: 120vh; /* Plus grand pour assurer la visibilité complète */
    }

    .banner-content {
        padding: 4rem 0;
    }
}

@media (max-width: 768px) {
  .banner-content {
    padding: 20px;
  }

  .banner-title {
    font-size: 2.5rem;
  }

  .banner-subtitle {
    font-size: 1.2rem;
  }

  .scroll-indicator {
    bottom: 20px;
  }
}

@media (max-width: 480px) {
  .banner-title {
    font-size: 2rem;
  }

  .banner-subtitle {
    font-size: 1rem;
  }
}

