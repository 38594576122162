* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
    
    
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    /*font-weight: 400;*/
    overflow-x: hidden;
    position: relative;
    font-family: 'Quark', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}


a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__text {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        margin: 4rem 2rem;
    }
}

.App {
    position: relative;
    min-height: 100vh;
    background-color: #7e7e7e;
}

.background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
}

.App.menu-open {
    color: #333;
    /*font-family: Arial, sans-serif;*/
    /*font-weight: bold;*/
    
}

.background-shapes {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

.grid {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

.big-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circle-svg {
  width: 500px;
  height: 500px;
  
}

.circle-path {
  fill: none;
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 2;
  stroke-dasharray: 1570;
  stroke-dashoffset: 1570;
  animation: drawCircle 2s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes drawCircle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  animation: floatAnimation 8s infinite ease-in-out;
}

.shape-1 {
  width: 150px;
  height: 150px;
  left: 5%;
  top: 20%;
  background: rgba(255, 255, 255, 0.1);
  animation-delay: 0s;
}

.shape-2 {
  width: 200px;
  height: 200px;
  right: 10%;
  top: 40%;
  background: rgba(0, 255, 255, 0.1);
  animation-delay: 2s;
}

.shape-3 {
  width: 120px;
  height: 120px;
  left: 15%;
  bottom: 20%;
  background: rgba(255, 0, 255, 0.1);
  animation-delay: 4s;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
}

.shape-square {
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.1);
  animation: rotateAnimation 10s infinite linear;
}

.shape-triangle {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 86.6px solid rgba(255, 255, 255, 0.1);
  animation: pulseAnimation 4s infinite ease-in-out;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 0.3;
  }
}

/* iPhone SE, petits téléphones */
@media screen and (max-width: 375px) {
    .background-overlay {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

/* iPhone 12 Pro */
@media screen and (max-width: 390px) {
    /* Styles spécifiques */
}

/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
    /* Styles spécifiques */
}

/* Tablettes */
@media screen and (max-width: 768px) {
    /* Styles spécifiques */
}

.side-title {  /*bug le texte se décale vers la gauche quand je scroll*/
  position: fixed;
  left: 10.5%;
  top: 5vh;
  transform: translateY(-50%) rotate(90deg);
  transform-origin: left center;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.8);
  color: transparent;
  z-index: 10;
  transition: all 0.5s ease;
}

.side-title.fixed {
  position: fixed;
  top: 10%; /* Centre verticalement */
  left: 10.5%;
}

@media screen and (max-width: 768px) {
  .side-title, .side-title.fixed {
    position: static;
    transform: none;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.projects-section {
  width: 100%;
  height: 100%;
}

.banner-section {
  width: 100%;
  height: 100%;
}

/* Base responsive styles */
@media (max-width: 1024px) {
  .side-title {
    font-size: 2.5rem;
  }

  .projects-section {
    padding: 60px 20px;
  }

  .banner-section {
    padding: 100px 20px;
  }
}

@media (max-width: 768px) {
  .side-title {
    font-size: 2rem;
    position: static !important;
    text-align: center;
    margin-bottom: 40px;
  }

  .projects-section {
    padding: 40px 15px;
  }

  .banner-section {
    padding: 80px 15px;
  }

  .background-shapes {
    display: none;
  }
}

@media (max-width: 480px) {
  .side-title {
    font-size: 1.8rem;
  }

  .projects-section {
    padding: 30px 10px;
  }

  .banner-section {
    padding: 60px 10px;
  }
}