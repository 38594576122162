.kiteboard-container {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

.section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.model-container {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    z-index: 0;
}

.first {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.4)
    );
    height: 120vh;
    position: relative;
}

.second {
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.97),
        rgba(240, 240, 240, 0.95)
    );
    position: relative;
    overflow: hidden;
}

.third {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(8, 89, 90, 0.8) 80%,
        rgba(8, 89, 90, 0) 100%
    );
    position: relative;
}

.fourth {
    background: rgba(0 ,0 , 0, 0.035);
    z-index: 0.5;
    pointer-events: none;
}

.fifth {
    background: rgba(0, 0, 0, 0.7);
}

.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  margin: 0;
  height: 100vh;
  position: relative;
  z-index: 2;
  padding: 0 5%;
}

.text-content {
  flex: 0.5;
  text-align: left;
  padding-left: 8%;
  /*margin-right: auto;*/
  
}

.text-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.text-content .description {
  font-size: 1.4rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  max-width: 500px;
}

.video-container {
  flex: 1.5;
  position: relative;
  padding-bottom: 45%;
  height: 0;
  max-width: 1200px;
  width: 100%;

}

.video-container iframe {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 60%;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

/* Responsive design */
@media (max-width: 968px) {
  .content-wrapper {
    flex-direction: column;
    gap: 2rem;
    padding-top: 130px; /* Espace pour la navbar */
  
  }

  .text-content-2 {
    padding-left: 0;
    margin-top: 100px;
  }

  

  .text-content {
    text-align: center;
  }

  .text-content h1 {
    font-size: 2.5rem;
  }

  .video-container {
    width: 100%;
  }
}

/* Animation au scroll */
.text-content {
  opacity: 0;
  transform: translateX(-50px);
  animation: slideIn 0.8s ease forwards;
}

.video-container {
  opacity: 0;
  transform: translateX(50px);
  animation: slideIn 0.8s ease forwards 0.2s;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Ajustement pour les écrans plus grands */
@media (min-width: 1600px) {
  .content-wrapper {
    max-width: 1600px;
  }

  .video-container {
    max-width: 900px;
  }
}

/* Pour les très grands écrans */
@media (min-width: 1800px) {
  .content-wrapper {
    max-width: 1800px;
  }

  .video-container {
    max-width: 1200px;
  }
}

/* Styles pour la section des couches */
.layer-details {
  margin-top: 2rem;
}

.layer-item {
  margin: 1.5rem 0;
  opacity: 0;
  transform: translateX(-20px);
  animation: slideIn 0.5s ease forwards;
}

.layer-item:nth-child(2) {
  animation-delay: 0.2s;
}

.layer-item:nth-child(3) {
  animation-delay: 0.4s;
}

.layer-item h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.layer-item p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.section.second {
  padding: 4rem 2rem;
  background-color: rgba(192, 198, 198, 0.468);
}

.content-wrapper-2 {
  max-width: 1200px;
  margin-left: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  z-index: 2;
  padding: 0 5%;
}

.text-content-2 {
  flex: 1;
  text-align: center;
  padding: 2rem;
}

.text-content-2 h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: bold;
}

.text-content-2 p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #666;
  margin-bottom: 1rem;
}

.image-content-2 {
  flex: 1;
  padding: 1rem;
}

.section-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-wrapper-2 {
    flex-direction: column;
    gap: 2rem;
  }

  .text-content-2 {
    padding: 1rem;
  }

  .text-content-2 h2 {
    font-size: 2rem;
  }

  .text-content-2 p {
    font-size: 1rem;
  }
}

.rolling-banner {
  width: 100%;
  background: rgba(255, 255, 255, 0.98);
  opacity: 1;
  overflow: hidden;
  padding: 1.5rem 0;
  position: relative;
  border-top: 1px solid rgba(0, 0, 255, 0.1);
  border-bottom: 1px solid rgba(0, 0, 255, 0.1);
  z-index: 1;
}

.banner-content {
  display: flex;
  align-items: center;
  animation: rolling 20s linear infinite;
  white-space: nowrap;
}

.banner-content span {
  color: #0066ff;
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 1rem;
  flex-shrink: 0;
}

.banner-content .dot {
  color: #01a6e8;
  opacity: 0.5;
  margin: 0 1rem;
}

@keyframes rolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Effet de superposition sur les côtés */
.rolling-banner::before,
.rolling-banner::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100px;
  height: 100%;
  z-index: 2;
}

.rolling-banner::before {
  left: 0;
  background: linear-gradient(to right, white, transparent);
}

.rolling-banner::after {
  right: 0;
  background: linear-gradient(to left, white, transparent);
}

/* Responsive */
@media (max-width: 768px) {
  .banner-content span {
    font-size: 1.8rem;
  }
  
  .rolling-banner {
    padding: 1rem 0;
  }
}

.forever-text {
  position: absolute;
  top: 85px; /* Ajustez selon la hauteur de votre navbar */
  left: 50%;
  transform: translateX(-50%);
  font-size: 90px;
  font-weight: 800;
  color: rgba(8, 89, 90, 0.242);
  letter-spacing: 15px;
  z-index: 1;
  text-transform: uppercase;
  pointer-events: none; /* Pour que le texte n'interfère pas avec les interactions */
}

/* Responsive */
@media (max-width: 768px) {
  .forever-text {
    font-size: 60px;
    letter-spacing: 8px;
    top: 100px;
  }
}

@media (max-width: 480px) {
  .forever-text {
    font-size: 40px;
    letter-spacing: 5px;
  }
}

.section.third {
  min-height: 450vh; /* Hauteur minimale */
  padding: 2rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Aligne le contenu en haut */
}

.text-container {
  margin: 2rem 0 3rem 0; /* Ajuste les marges */
  max-width: 800px;
  position: relative;
  z-index: 2;
}

.text-container h2 {
  margin-bottom: 1rem;
}

.images-sequence-vertical {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0; /* Réduit le padding vertical */
  flex-grow: 1; /* Permet au conteneur d'images de prendre l'espace restant */
  display: flex;
  /* align-items: center; */
  margin-top: 100px;
  position: relative;
  z-index: 2;
  width: 90%;
  max-width: 1200px;
}

.image-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem; /* Utilise rem pour un espacement plus cohérent */
  width: 100%;
  
}

.stacked-image {
  width: 100%;
  max-width: 900px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.stacked-image:hover {
  transform: scale(1.05);

}

/* Responsive */
@media (max-width: 768px) {
  .section.third {
    padding: 1rem;
  }

  .text-container {
    margin: 1rem 0 2rem 0;
  }

  .images-sequence-vertical {
    padding: 0 1rem;
  }

  .stacked-image {
    max-width: 250px;
  }

  .image-column {
    gap: 1.5rem;
  }
}

.scrolling-images {
  animation: scroll 10s linear infinite; /* Animation de défilement */
   
}

@keyframes scroll {
  0% {
    transform: translateY(0); /* Position de départ */
  }
  100% {
    transform: translateY(-100%); /* Position finale */
  }
}

.images-sequence-vertical {
  height: 80vh; /* Ajustez la hauteur selon vos besoins */
  position: relative; /* Nécessaire pour le positionnement absolu des images */
}

.image-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem; /* Espacement entre les images */
  position: absolute; /* Positionnement absolu pour le défilement */
  width: 100%;
}

.main-title {
  position: absolute;
  top: 120px; /* Ajustez selon la hauteur de votre navbar */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  z-index: 2;
}

.main-title h1 {
  font-size: 4rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Responsive */
@media (max-width: 768px) {
  .main-title h1 {
    font-size: 2.5rem;
    letter-spacing: 8px;
  }
}

@media (max-width: 480px) {
  .main-title h1 {
    font-size: 2rem;
    letter-spacing: 5px;
  }
}

/* Styles globaux */
body {
  font-family: 'Montserrat', sans-serif; /* Police principale */
}

/* Section 1 */
.forever-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 90px;
  font-weight: 800;
  color: rgba(8, 89, 90, 0.242);
  letter-spacing: 15px;
}

.text-content h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1.5rem;
  letter-spacing: 2px;
}

.text-content .description {
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
}

/* Section 2 */
.text-content-2 h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 2rem;
  letter-spacing: 1px;
}

.text-content-2 p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #666;
  font-weight: 400;
}

/* Section 3 */
.text-container h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;

}

.text-container p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
}

/* Bandeau défilant */
.banner-content span {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #01dada;
  letter-spacing: 2px;
}

/* Responsive */
@media (max-width: 1024px) {
  .text-content h1 {
    font-size: 3rem;
  }
  
  .text-content-2 h2,
  .text-container h2 {
    font-size: 2.5rem;
  }
  
  .banner-content span {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .forever-text {
    font-size: 60px;
  }

  .text-content h1 {
    font-size: 2.5rem;
  }

  .text-content .description {
    font-size: 1.2rem;
  }

  .text-content-2 h2,
  .text-container h2 {
    font-size: 2rem;
  }

  .text-content-2 p,
  .text-container p {
    font-size: 1rem;
  }

  .banner-content span {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .forever-text {
    font-size: 40px;
  }

  .text-content h1 {
    font-size: 2rem;
  }

  .text-content-2 h2,
  .text-container h2 {
    font-size: 1.8rem;
  }
}

/* Styles globaux */
body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}

/* Section 1 - Hero */
.section.first {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
  height: 120vh;
  position: relative;
}

.main-title h1 {
  font-size: 5rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  letter-spacing: 20px;
  animation: fadeInDown 1s ease-out;
}

.forever-text {
  font-size: 120px;
  font-weight: 900;
  color: rgba(8, 89, 90, 0.15);
  letter-spacing: 25px;
  animation: fadeIn 2s ease-out;
}

/* Section 2 - Save our playground */
.section.second {
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.97),
    rgba(240, 240, 240, 0.95)
  );
  padding: 6rem 0;
  position: relative;
  overflow: hidden;*/
  background: rgba(240, 240, 240, 1);
}

.content-wrapper-2 {
  max-width: 1400px;
  margin: 0 auto;
  gap: 6rem;
}

.text-content-2 h2 {
  font-size: 3.5rem;
  font-weight: 800;
  color: #222;
  margin-bottom: 2rem;
  line-height: 1.2;
}

.text-content-2 p {
  font-size: 1.3rem;
  line-height: 2;
  color: #555;
}

.section-image {
  border-radius: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  transition: transform 0.5s ease;
}

.section-image:hover {
  transform: translateY(-10px);
}

/* Section 3 - Technologies */
.section.third {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(8, 89, 90, 0.8) 70%,
    rgba(8, 89, 90, 0) 100%
  );
  min-height: 450vh;
  position: relative;
}

.text-container {
  margin-bottom: 0px;
}

.text-container h2 {
  font-size: 3.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.text-container p {
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
}

.stacked-image {
  border-radius: 20px 20px 20px 20px;
  transition: all 0.5s ease;
}

.stacked-image:hover {
  transform: scale(1.03) translateX(10px);
}

/* Bandeau défilant */
.rolling-banner {
  background: rgba(255, 255, 255, 0.98);
  padding: 2rem 0;
}

.banner-content span {
  font-size: 3rem;
  font-weight: 700;
  color: #01a6e8;
  text-transform: uppercase;
  letter-spacing: 3px;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .main-title h1 {
    font-size: 4rem;
    letter-spacing: 15px;
  }
  
  .forever-text {
    font-size: 90px;
    letter-spacing: 20px;
  }
  
  .text-content-2 h2 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .main-title h1 {
    font-size: 2.5rem;
    letter-spacing: 10px;
  }
  
  .forever-text {
    font-size: 60px;
    letter-spacing: 15px;
  }
  
  .text-content-2 h2 {
    font-size: 2.5rem;
  }
  
  .text-content-2 p {
    font-size: 1.1rem;
  }
  
  .banner-content span {
    font-size: 2rem;
  }
  
  .text-container h2 {
    font-size: 2.5rem;
  }
  
  .text-container p {
    font-size: 1.1rem;
    padding: 0 1rem;
  }
}

/* Effets de survol globaux */
a, button {
  transition: all 0.3s ease;
}

a:hover, button:hover {
  opacity: 0.8;
}

/* Effet subtil pour la section 2 */
.section.second::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 30% 50%,
    rgba(8, 89, 90, 0.05),
    transparent 60%
  );
  pointer-events: none;
}

/* Effet de texture subtil pour la section 3 */
.section.third::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.03) 50%,
    transparent 100%
  );
  pointer-events: none;
}

/* Effet de vignette pour toutes les sections */
.section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    transparent 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  pointer-events: none;
  z-index: 1;
}

/* Assurez-vous que le contenu reste au-dessus des effets */
.content-wrapper,
.content-wrapper-2,
.text-container,
.images-sequence-vertical {
  position: relative;
  z-index: 2;
}

.layer-info-panel {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.95);
    padding: 30px;
    border-radius: 20px;
    max-width: 400px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.4s ease;
    z-index: 1000; /* Valeur élevée pour s'assurer qu'il soit au-dessus */
}

/* Assurez-vous que le contenu du panel est aussi au-dessus */
.layer-info-content {
    position: relative;
    z-index: 1001; /* Une valeur encore plus élevée que le panel */
    opacity: 0;
    animation: slideIn 0.5s 0.2s forwards;
}

/* Styles pour la section 4 */
.section.fourth {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px; /* Espace pour la navbar */
    /*background: rgba(250, 250, 250, 0.8);*/
    z-index: -2;
}

.section.fourth h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-bottom: 2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: -600px;
  
}

.section.fourth p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 2rem;
}

/* Responsive */
@media (max-width: 768px) {
    .section.fourth {
        padding-top: 80px;
    }

    .section.fourth h2 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }

    .section.fourth p {
        font-size: 1.1rem;
        line-height: 1.6;
    }
}

/* Styles globaux pour tous les titres h1, h2 */
h1, h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}

/* Styles globaux pour tous les paragraphes */
p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.3rem;
  line-height: 1.8;
  font-weight: 400;
}

/* Ajustements spécifiques pour la couleur selon les sections */
.section.first {
  h1, h2 { color: #ffffff; }
  p { color: rgba(255, 255, 255, 0.9); }
}

.section.second {
  h1, h2 { color: #333; }
  p { color: #666; }
}

.section.third {
  h1, h2 { color: #ffffff; }
  p { color: rgba(255, 255, 255, 0.9); }
}

.section.fourth {
  h1, h2 { color: #ffffff; }
  p { color: rgba(255, 255, 255, 0.9); }
}

/* Responsive */
@media (max-width: 1024px) {
  h1, h2 { font-size: 3rem; }
  p { font-size: 1.2rem; }
}

@media (max-width: 768px) {
  h1, h2 { font-size: 2.5rem; }
  p { font-size: 1.1rem; }
}

@media (max-width: 480px) {
  h1, h2 { font-size: 2rem; }
  p { font-size: 1rem; }
}

@media (max-width: 768px) {
  .section {
    padding: 60px 20px;
  }

  .content-wrapper {
    flex-direction: column;
  }

  .content-wrapper-2 {
    justify-content: center;
    padding: 0;
    
  }

  .image-content-2 {
    margin-top: 0;
  }

  .text-content {
    text-align: center;
    padding: 0 20px;
  }

  .video-container {
    width: 100%;
    padding-bottom: 56.25%;
  }

  .rolling-banner {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .section h1 {
    font-size: 2rem;
  }

  .section p {
    font-size: 1rem;
  }
}

