.message {
    font-size: 48px;
    color: #333;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Arial, sans-serif;
    font-weight: bold;
}

.project-header {
  text-align: center;
  padding-top: 100px;

}

.project-header h1 {
  font-size: 4rem;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: inline-block;
  position: relative;
}

.project-header h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #92aaa9;
}

