* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: #f0f0f0;
}

.portfolio-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 3rem;
  color: #333;
}