.experience-section {
  padding: 4rem 0;
  max-width: 1200px;
  margin: 0 auto;
}

.experience-header {
  position: relative;
  margin-bottom: 4rem;
  text-align: center;
}

.experience-header h2 {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.underline {
  height: 4px;
  width: 100px;
  background: #01dada;
  opacity: 0.5;
  margin: 0 auto;
  position: relative;
}

.underline::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 50px;
  background: rgba(255, 255, 255, 0.3);
  right: -60px;
  top: 0;
}

.timeline {
  position: relative;
  padding-left: 3rem;
  max-width: 800px;
  margin: 0 auto;
  margin-left: calc(50% - 400px);
}

.timeline::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  bottom: 8px;
  width: 4px;
  background: rgba(1, 218, 218, 0.5);
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(1, 218, 218, 0.5);
}

.experience-item {
  position: relative;
  padding: 2rem 0;
}

.timeline-dot {
  position: absolute;
  left: -3rem;
  top: 3rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(1, 218, 218, 1);
  border: 4px solid rgba(1, 218, 218, 0.5);
  box-shadow: 0 0 0 4px rgba(1, 218, 218, 0.2);
  transform: translateX(-8px);
}

.experience-content {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.experience-content:hover {
  transform: translateX(10px);
  background: rgba(255, 255, 255, 0.08);
}

.date {
  color: rgba(1, 218, 218, 0.5);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  display: inline-block;
  padding: 0.3rem 1rem;
  background: rgba(10, 10, 11, 0.1);
  border-radius: 20px;
}

.experience-content h3 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.experience-content h4 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.experience-content p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

/* Animation d'entrée */
.experience-item {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Délai d'animation pour chaque item */
.experience-item:nth-child(1) { animation-delay: 0.2s; }
.experience-item:nth-child(2) { animation-delay: 0.4s; }
.experience-item:nth-child(3) { animation-delay: 0.6s; }

/* Responsive */
@media (min-width: 1441px) {
  .timeline {
    margin-left: calc(50% - 400px);
  }
  .timeline-dot {
    transform: translateX(-8px);
  }
}

@media (max-width: 1440px) {
  .timeline {
    margin-left: calc(40% - 300px);
  }
}

@media (max-width: 1024px) {
  .timeline {
    margin-left: 4rem;
    padding-left: 2rem;
  }
  .timeline-dot {
    left: -2rem;
    transform: translateX(-8px);
  }
}

@media (max-width: 768px) {
  .timeline {
    margin-left: 3rem;
    padding-left: 2rem;
  }
  .timeline-dot {
    transform: translateX(-8px);
  }
}

@media (max-width: 480px) {
  .timeline {
    margin-left: 2rem;
    padding-left: 2rem;
  }
  .timeline-dot {
    width: 16px;
    height: 16px;
    transform: translateX(-6px);
  }
  .timeline::before {
    width: 3px;
  }
}

@media (max-width: 320px) {
  .timeline {
    margin-left: 1.5rem;
    padding-left: 1.5rem;
  }
  .timeline-dot {
    left: -1.5rem;
  }
}